import { mqWidth880px, mqWidth1296px } from '../base/mediaQueryDefinitions';

/**
 * Font metric for Rijks Sans.
 *
 * Generated using [Capsize](https://seek-oss.github.io/capsize/)
 */
export const fontMetrics = {
	capHeight: 656,
	ascent: 899,
	descent: -279,
	lineGap: 0,
	unitsPerEm: 1000,
};

/**
 * Unit of messurement that equals the cap height of the root font size
 *
 * Is exposed as a CSS Custom Property
 */
export const rootCapInPx = {
	all: 11, // font-size: 16.7683 ≈ 17
	[mqWidth880px.min]: 12, // font-size: 18.2927 ≈ 18
	[mqWidth1296px.min]: 13, // font-size: 19.8171 ≈ 20
} as const;
