import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconChevronRight: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '20.49 11.51 23.61 40.6', width: '23.61', height: '40.6' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		aria-hidden={true}
		role="img"
		{...props}
	>
		<path
			d="M52.6,23.4249889 L49.1555976,20 L32.3,36.7611334 L15.4444024,20 L12,23.4249889 L32.3,43.6111111 L52.6,23.4249889"
			transform="translate(32.300000, 31.805556) rotate(-90.000000) translate(-32.300000, -31.805556) "
		/>
	</svg>
);
