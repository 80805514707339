import { css } from 'styled-components';

import {
	StylePropsMap,
	StyleMap,
	FlattenSimpleInterpolationMaybe,
	ComponentStyleMap,
	InteractiveComponentProps,
	MediaQueryMap,
	ComponentStyleProps,
} from './StyleProps.types';

const getCSSForStylePropsWithDefaults = <StyleProps extends StylePropsMap>(
	styleMap: StyleMap<StyleProps> | undefined,
	styleProps: StyleProps | undefined,
): FlattenSimpleInterpolationMaybe => {
	if (styleMap == null || styleProps == null) return undefined;

	const CSS: Array<FlattenSimpleInterpolationMaybe> = [];
	for (const [propName, getCSS] of Object.entries(styleMap)) {
		const value = styleProps[propName];

		CSS.push(getCSS(value));
	}

	return CSS;
};

const getCSSForStyleProps = <StyleProps extends StylePropsMap>(
	styleMap: StyleMap<StyleProps> | undefined,
	styleProps: StyleProps | undefined,
): FlattenSimpleInterpolationMaybe => {
	if (styleMap == null || styleProps == null) return undefined;

	const CSS: Array<FlattenSimpleInterpolationMaybe> = [];
	for (const [propName, getCSS] of Object.entries(styleMap)) {
		const value = styleProps[propName];
		if (value != null) {
			CSS.push(getCSS(value));
		}
	}

	return CSS;
};

export const hoverSelfSelector = css`&:where(a, button, input, textarea, [data-hoverable~='self']):where(:not([disabled], [aria-disabled])):hover`;
export const hoverDescendantsSelector = css`*:where(a, button, [data-hoverable~='descendants']):where(:not([disabled], [aria-disabled])):hover &`;

export const activeSelfSelector = css`&:where(a, button, input, textarea):where(:not([disabled], [aria-disabled])):active`;
export const activeDescendantsSelector = css`*:where(a, button):where(:not([disabled], [aria-disabled])):active &`;

export const focusSelfSelector = css`&:where(a, button, input, textarea, [tabindex]):focus`;
export const focusDescendantsSelector = css`*:where(a, button):focus &`;

export const focusVisibleSelfSelector = css`&:where(a, button, input, textarea, [tabindex]):focus-visible`;
export const focusVisibleDescendantsSelector = css`*:where(a, button):focus-visible &`;

export const checkedSelfSelector = css`&:where(input):where([type='checkbox'], [type='radio']):checked`;
export const checkedDescendantsSelector = css`*:where(input):where([type='checkbox'], [type='radio']):checked ~ *:where(label &, &)`;

export const disabledSelfSelector = css`&:where(input, textarea, button):disabled`;
export const disabledDescendantsSelector = css`*:where(input, textarea, button):disabled ~ *:where(label &, &)`;

const getInteractiveCSS = <
	StaticStyleProps extends StylePropsMap,
	InteractiveStyleProps extends StylePropsMap | undefined,
>(
	{
		hover: hoverStyleMap,
		active: activeStyleMap,
		focus: focusStyleMap,
		checked: checkedStyleMap,
		disabled: disabledStyleMap,
	}: ComponentStyleMap<StaticStyleProps, InteractiveStyleProps>,
	{
		$hover: hoverStyleProps,
		$active: activeStyleProps,
		$focus: focusStyleProps,
		$checked: checkedStyleProps,
		$disabled: disabledStyleProps,
	}: InteractiveComponentProps<StaticStyleProps, InteractiveStyleProps>,
) => {
	const CSS: Array<FlattenSimpleInterpolationMaybe> = [];

	const hoverCSS = getCSSForStyleProps(hoverStyleMap, hoverStyleProps);
	if (hoverCSS != null) {
		CSS.push(css`
			${hoverSelfSelector},
			${hoverDescendantsSelector} {
				${hoverCSS};
			}
		`);
	}

	const activeCSS = getCSSForStyleProps(activeStyleMap, activeStyleProps);
	if (activeCSS != null) {
		CSS.push(css`
			${activeSelfSelector},
			${activeDescendantsSelector} {
				${activeCSS};
			}
		`);
	}

	const focusCSS = getCSSForStyleProps(focusStyleMap, focusStyleProps);
	if (focusCSS != null) {
		CSS.push(css`
			@supports not selector(:focus-visible) {
				${focusSelfSelector},
				${focusDescendantsSelector} {
					${focusCSS};
				}
			}
			@supports selector(:focus-visible) {
				${focusVisibleSelfSelector},
				${focusVisibleDescendantsSelector} {
					${focusCSS};
				}
			}
		`);
	}

	const checkedCSS = getCSSForStyleProps(checkedStyleMap, checkedStyleProps);
	if (checkedCSS != null) {
		CSS.push(css`
			${checkedSelfSelector},
			${checkedDescendantsSelector} {
				${checkedCSS};
			}
		`);
	}

	const disabledCSS = getCSSForStyleProps(disabledStyleMap, disabledStyleProps);
	if (checkedCSS != null) {
		CSS.push(css`
			${disabledSelfSelector},
			${disabledDescendantsSelector} {
				${disabledCSS};
			}
		`);
	}

	return CSS;
};

const getResponsiveCSS = <
	StaticStyleProps extends StylePropsMap,
	InteractiveStyleProps extends StylePropsMap | undefined,
>(
	componentStyleMap: ComponentStyleMap<StaticStyleProps, InteractiveStyleProps>,
	mediaQueryStyleProps:
		| MediaQueryMap<StaticStyleProps, InteractiveStyleProps>
		| undefined,
): FlattenSimpleInterpolationMaybe => {
	if (mediaQueryStyleProps == null) return undefined;

	const CSS: Array<FlattenSimpleInterpolationMaybe> = [];
	for (const [mqText, mqStyleProps] of Object.entries(mediaQueryStyleProps)) {
		CSS.push(css`
			@media ${mqText} {
				${getCSSForStyleProps(componentStyleMap.normal, mqStyleProps)};
				${getInteractiveCSS(componentStyleMap, mqStyleProps)};
			}
		`);
	}

	return CSS;
};

/**
 * Takes a map of styling functions and generates CSS
 * while taking interactive states such as :hover into account
 * It also supports media queries.
 *
 * @param componentStyleMap How to render CSS for static and interactive props
 * @param componentStyleProps Static and interactive props inside or outside of media queries
 * 
 * @example
 * ```tsx
 * 
 type StaticProps = {
	italic?: boolean;
};

type InteractiveProps = {
	textColor?: string;
};

const staticStyleMap: StyleMap<StaticProps & InteractiveProps> = {
	italic: italic => {
		return css`
			font-style: ${italic ? 'italic' : 'normal'};
		`;
	},
	textColor: textColor => {
		return css`
			color: ${textColor};
		`;
	},
};
const interactiveStyleMap: StyleMap<InteractiveProps> = {
	textColor: staticStyleMap.textColor,
};;

 getCSSFromStyleProps(
	{
		normal: staticStyleMap,
		hover: interactiveStyleMap,
		active: interactiveStyleMap,
		focus: interactiveStyleMap,
		checked: interactiveStyleMap,
		disabled: interactiveStyleMap,
	},
	{
		textColor: 'red',
		hover: {
			textColor: 'blue',
		},
		mq: {
			'(min-width: 600px)': {
				italic: true,
				textColor: 'green',
				hover: {
					textColor: 'purple'
				}
			}
		}
	}
)
// Returns:
// css`
// 	font-style: normal;
// 	color: red;
// 	&:hover {
// 		color: blue,
// 	}
// 	;@media (min-width: 600px) {
// 		font-style: italic;
// 		color: green;
// 		&:hover {
// 			color: purple;
// 		}
// 	}
// `
 ```
 */
export const getCSSFromStyleProps = <
	StaticStyleProps extends StylePropsMap,
	InteractiveStyleProps extends StylePropsMap | undefined,
>(
	componentStyleMap: ComponentStyleMap<StaticStyleProps, InteractiveStyleProps>,
	componentStyleProps: ComponentStyleProps<
		StaticStyleProps,
		InteractiveStyleProps
	>,
): FlattenSimpleInterpolationMaybe => {
	return css`
		${getCSSForStylePropsWithDefaults(
			componentStyleMap.normal,
			componentStyleProps,
		)}
		${getInteractiveCSS(componentStyleMap, componentStyleProps)};
		${getResponsiveCSS(componentStyleMap, componentStyleProps.$mq)}
	`;
};
