export const questionTranslations = {
	// Questions
	'rechtsvormen-question-alone-or-together': {
		en: 'Alone or together',
		nl: 'Alleen of samen',
	},
	'rechtsvormen-question-my-ambition': {
		en: 'My ambition',
		nl: 'Mijn ambitie',
	},
	'rechtsvormen-question-business-risk': {
		en: 'Business risk',
		nl: 'Bedrijfsrisico',
	},
	'rechtsvormen-question-revenue': {
		en: 'Profit',
		nl: 'Winst',
	},
	'rechtsvormen-question-staffing': {
		en: 'Staff',
		nl: 'Personeel',
	},

	// Descriptions to 'why does this matter' in side-overlay
	'rechtsvormen-description-alone-or-together': {
		en: 'There are different types of collaboration: each legal structure comes with its own conditions and consequences. For instance, if you alone are liable for the entire company debt, or only for part of it; or, how easy it is to get out of the collaboration, without endangering its continued existence.',
		nl: 'U kunt op verschillende manieren samenwerken. Elke rechtsvorm heeft zijn eigen voorwaarden en consequenties. Bijvoorbeeld of u alleen aansprakelijk bent voor uw eigen schulden of ook voor die van anderen. En of u makkelijk uit de samenwerking kunt stappen zonder het bedrijf in gevaar te brengen.',
	},
	'rechtsvormen-description-my-ambition': {
		en: 'Some legal structures require the services of an actuary when starting out. And some legal structures come with administrative obligations, like having to produce and deposit annual accounts at the KVK. If your business risk increases, for instance if your company is growing, a legal structure with legal personality is an option worth looking into. It will limit the risk you run personally.',
		nl: 'Sommige rechtsvormen kunt u alleen oprichten met tussenkomst van een notaris. Daarnaast kunt u te maken krijgen met verplichtingen rond de administratie, zoals het opstellen en deponeren van jaarstukken bij KVK. Als door groei uw risico’s toenemen, is een rechtsvorm met rechtspersoonlijkheid handig. Uw persoonlijk risico blijft dan beperkt.',
	},
	'rechtsvormen-description-business-risk': {
		en: "Who is liable for business risks if things don't work out depends on your legal structure. If you choose a legal structure with legal personality, such as the private limited company, the company will be liable for any debts. If you choose a legal structure without legal personality, such as a commercial partnership, you will be personally liable, with your private capital.",
		nl: 'Uw rechtsvorm bepaalt wie het bedrijfsrisico opvangt als het misgaat. Bij ondernemingsvormen met rechtspersoonlijkheid, zoals de bv, is het bedrijf aansprakelijk voor eventuele schulden. Bij ondernemingsvormen zonder rechtspersoonlijkheid bent u met uw eigen geld aansprakelijk.',
	},
	'rechtsvormen-description-revenue': {
		en: "Which taxes you pay depends on your legal structure. Your company has to pay corporate tax if it has a legal structure with legal personality, and income tax if it doesn't have a legal structure with legal personality. If your profit increases, paying corporate tax becomes more advantageous. There are also differences in what you can deduct.",
		nl: 'Uw rechtsvorm bepaalt welke belastingen u betaalt. Bij een ondernemingsvorm  met rechtspersoonlijkheid betaalt u vennootschapsbelasting. Bij een rechtsvorm zonder rechtspersoonlijkheid betaalt u inkomstenbelasting. Als uw winst toeneemt, dan is het voordeliger om vennootschapsbelasting te betalen. Ook verschillen de aftrekposten.',
	},
	'rechtsvormen-description-staffing': {
		en: "You can hire staff no matter what your legal structure is. Hiring staff does involve risks. You have obligations toward your staff, that may be hard to meet if your company doesn't do well. Depending on your company's legal structure, you may be personally liable for these risks.",
		nl: 'U kunt met elke rechtsvorm personeel aannemen. Als u personeel aanneemt, brengt dit risico’s met zich mee. Als het slecht gaat met uw bedrijf, heeft u verplichtingen naar uw personeel. De ondernemingsvorm bepaalt of u persoonlijk aansprakelijk bent voor dit risico of niet.',
	},

	// Answers: alone-or-together
	'rechtsvormen-answer-start-a-business-alone': {
		en: 'I am starting a business on my own',
		nl: 'Ik ga alleen ondernemen',
	},
	'rechtsvormen-answer-start-a-business-with-a-partner': {
		en: 'I am starting a business with a partner',
		nl: 'Ik ga ondernemen met een compagnon',
	},
	'rechtsvormen-answer-start-a-business-with-a-group-of-partners': {
		en: 'I am starting a business with a group of partners',
		nl: 'Ik ga ondernemen met een grote groep compagnons',
	},
	'rechtsvormen-answer-start-a-business-with-life-partner': {
		en: 'I am starting a business with my life-partner',
		nl: 'Ik ga ondernemen met mijn levenspartner',
	},
	'rechtsvormen-answer-want-to-collaborate-independently': {
		en: 'I want to collaborate on an independent basis',
		nl: 'Ik wil onafhankelijk samenwerken',
	},
	'rechtsvormen-answer-want-to-collaborate-under-one-name': {
		en: 'I want to collaborate under a shared name',
		nl: 'Wij willen samenwerken onder één naam',
	},
	// Answers: my-ambition
	'rechtsvormen-answer-want-to-start-as-quickly-as-possible': {
		en: 'I want to start as soon as possible, with a minimum of administrative obligations',
		nl: 'Ik wil snel starten met weinig administratieve verplichtingen',
	},
	'rechtsvormen-answer-want-to-start-as-quickly-as-possible_plural': {
		en: 'We want to start as soon as possible, with a minimum of administrative obligations',
		nl: 'Wij willen snel starten met weinig administratieve verplichtingen',
	},
	'rechtsvormen-answer-want-to-expand-quickly': {
		en: 'I want my business to grow quickly',
		nl: 'Ik wil mijn bedrijf snel laten groeien',
	},
	'rechtsvormen-answer-want-to-expand-quickly_plural': {
		en: 'We want our business to grow quickly',
		nl: 'Wij willen ons bedrijf snel laten groeien',
	},
	// Answers: business-risk
	'rechtsvormen-answer-can-afford-to-run-business-risk-financially': {
		en: 'I have enough funds to cover my business risks',
		nl: 'Ik kan mijn bedrijfsrisico zelf betalen',
	},
	'rechtsvormen-answer-can-afford-to-run-business-risk-financially_plural': {
		en: 'We have enough funds to cover our business risks',
		nl: 'Wij kunnen ons bedrijfsrisico zelf betalen',
	},
	'rechtsvormen-answer-business-risk-is-greater-than-can-afford-to-run-financially':
		{
			en: 'I do not have enough funds to cover my business risks',
			nl: 'Mijn bedrijfsrisico is groter dan ik zelf kan betalen',
		},
	'rechtsvormen-answer-business-risk-is-greater-than-can-afford-to-run-financially_plural':
		{
			en: 'We do not have enough funds to cover our business risks',
			nl: 'Ons bedrijfsrisico is groter dan wij zelf kunnen betalen',
		},
	'rechtsvormen-answer-make-use-of-external-capital': {
		en: 'I have access to external funding',
		nl: 'Ik gebruik vreemd vermogen',
	},
	'rechtsvormen-answer-make-use-of-external-capital_plural': {
		en: 'We have access to external funding',
		nl: 'Wij gebruiken vreemd vermogen',
	},
	'rechtsvormen-answer-make-use-of-private-capital': {
		en: 'I am using only private capital',
		nl: 'Ik gebruik eigen vermogen',
	},
	'rechtsvormen-answer-make-use-of-private-capital_plural': {
		en: 'We are using only private capital',
		nl: 'Wij gebruiken eigen vermogen',
	},
	// Answers: revenue
	'rechtsvormen-answer-expected-turnover-less-than-nk-euro': {
		en: 'I expect to make a profit of less than €150,000',
		nl: 'Ik verwacht een winst van minder dan € 150.000',
	},
	'rechtsvormen-answer-expected-turnover-less-than-nk-euro_plural': {
		en: 'We expect to make a profit of less than €150,000',
		nl: 'Wij verwachten een winst van minder dan € 150.000',
	},
	'rechtsvormen-answer-expected-turnover-more-than-nk-euro': {
		en: 'I expect to make a profit of over €150,000',
		nl: 'Ik verwacht een winst van meer dan € 150.000',
	},
	'rechtsvormen-answer-expected-turnover-more-than-nk-euro_plural': {
		en: 'We expect to make a profit of over €150,000',
		nl: 'Wij verwachten een winst van meer dan € 150.000',
	},
	// Answers: staffing
	'rechtsvormen-answer-work-without-staff': {
		en: 'I will not be hiring staff (yet)',
		nl: 'Ik werk zonder personeel',
	},
	'rechtsvormen-answer-work-without-staff_plural': {
		en: 'We will not be hiring staff (yet)',
		nl: 'Wij werken zonder personeel',
	},
	'rechtsvormen-answer-work-with-staff': {
		en: 'I will be hiring staff',
		nl: 'Ik werk met personeel',
	},
	'rechtsvormen-answer-work-with-staff_plural': {
		en: 'We will be hiring staff',
		nl: 'Wij werken met personeel',
	},
} as const;
