export const eenmanszaakTranslations = {
	'rechtsvorm-eenmanszaak': {
		en: 'Sole proprietorship (eenmanszaak)',
		nl: 'Eenmanszaak',
	},
	'rechtsvorm-eenmanszaak-summary-point-1': {
		en: 'starting is quick and easy',
		nl: 'snel en eenvoudig op te richten',
	},
	'rechtsvorm-eenmanszaak-summary-point-2': {
		en: 'you can only register one sole proprietorship',
		nl: 'maximaal één eenmanszaak',
	},
	'rechtsvorm-eenmanszaak-summary-point-3': {
		en: 'you can find the current [registration fee](https://www.kvk.nl/english/registration/registration-fee/) on KVK.nl/english',
		nl: 'huidige [inschrijfkosten](https://www.kvk.nl/inschrijven-en-wijzigen/inschrijven-bij-de-kamer-van-koophandel/inschrijfvergoeding/) vindt u op KVK.nl',
	},
	'rechtsvorm-eenmanszaak-summary-point-4': {
		en: 'personally liable for company debts',
		nl: 'met eigen geld aansprakelijk voor eventuele schulden',
	},
	'rechtsvorm-eenmanszaak-summary-point-5': {
		en: 'possible tax relief schemes: private business ownership allowance, SME profit exemption, tax relief for new companies',
		nl: 'recht op zelfstandigenaftrek en mkb-winstvrijstelling, starters profiteren van startersaftrek',
	},
} as const;
