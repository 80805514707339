import React from 'react';
import classnames from 'classnames';

import { IconLinkExternal } from '@dop/ui-icons/svg/linkExternal';

const ExternalIcon = ({
	isExternal = true,
	externalIconClassName = 'goIcon externalLinkIcon',
	className,
}) => {
	if (!isExternal) {
		return null;
	}

	return (
		<IconLinkExternal
			className={classnames(className, externalIconClassName)}
		/>
	);
};

export default ExternalIcon;
