// A collections of functions to manipulate immutable objects
// A check is performed to make sure the immutable object supports the method
// They follow the form of:
// (collection) => collection[method]()
// All exports from this module are tested to follow the above form
import { hasMethod } from './functional';

/*eslint-disable no-undefined*/
export const first = (collection) =>
	hasMethod('first', collection) ? collection.first() : undefined;

export const last = (collection) =>
	hasMethod('last', collection) ? collection.last() : undefined;

export const clear = (collection) =>
	hasMethod('clear', collection) ? collection.clear() : undefined;

export const flatten = (collection) =>
	hasMethod('flatten', collection) ? collection.flatten() : undefined;

export const toList = (collection) =>
	hasMethod('toList', collection) ? collection.toList() : undefined;

export const toJS = (collection) =>
	hasMethod('toJS', collection) ? collection.toJS() : undefined;

export const pop = (collection) =>
	hasMethod('pop', collection) ? collection.pop() : undefined;

export const toObject = (collection) =>
	hasMethod('toObject', collection) ? collection.toObject() : {};

export const toArray = (collection) =>
	hasMethod('toArray', collection) ? collection.toArray() : [];

export const count = (collection) =>
	hasMethod('count', collection) ? collection.count() : 0;
