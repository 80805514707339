import { defaultTheme } from './defaultTheme';

const theme = Object.assign({}, defaultTheme, {
	header: {
		heading: {
			paddingTop: '2.4rem',
			paddingBottom: '2.4rem',
			paddingTopMobile: '1.2rem',
			paddingBottomMobile: '1.2rem',
		},
	},
});

export default theme;
