import environmentConfig from 'moduleAlias/environmentConfig';

import { negate } from './functional';

export const parseUrl = (url, baseUrl = environmentConfig.originUrl) => {
	try {
		return new URL(url, baseUrl);
	} catch (e) {
		console.error('Parsing url failed', e);
		return { toString: () => '' };
	}
};

// allowed protocols
const protocolWhitelist = [
	'http:',
	'https:',
	'mailto:',
	'whatsapp:',
	'tel:',
	'blob:',
	null, // For internal links
];

export const isProtocolWhitelisted = (href, origin) =>
	protocolWhitelist.includes(parseUrl(href, origin).protocol);

export const isEmail = (href) => {
	const protocol = new URL(href, environmentConfig.originUrl).protocol;
	return protocol === 'mailto:';
};

export const isInternalLink = (targetHref) => {
	const { origin: targetOrigin } = new URL(
		targetHref,
		environmentConfig.originUrl,
	);

	return targetOrigin === environmentConfig.originUrl;
};

export const isExternalLink = negate(isInternalLink);

/**
 *
 * @param {string} url
 * @param {string?} origin
 * @returns {string}
 */
export const sanitizeUrl = (url, origin = environmentConfig.orgin) => {
	const parsedUrl = parseUrl(url, origin);

	if (parsedUrl.pathname) {
		parsedUrl.pathname = parsedUrl.pathname.replace(/\/+/g, '/');
	}

	return parsedUrl.toString();
};

// takes a full url string
export const splitUrlPath = (url) => {
	const parsedUrl = parseUrl(sanitizeUrl(url));

	return parsedUrl.pathname
		? parsedUrl.pathname.split('/').filter((v) => v)
		: [];
};

// takes a path string
export const splitPath = (pathname = '') => {
	const [, ...pathSegments] = pathname.split('/');

	return pathSegments;
};
