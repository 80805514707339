import { Property as CSSProperty } from 'csstype';

import { NegativeColor, PositiveColor } from '../colorTheme/ColorTheme.types';

export type Padding =
	| number
	| [number, number]
	| [number, number, number]
	| [number, number, number, number];

export type PaddingBlock = number | [number, number];

export type PaddingInline = number | [number, number];

export type Outline<
	Color extends PositiveColor | NegativeColor = PositiveColor | NegativeColor,
> = [
	outlineWidth: CSSProperty.OutlineWidth,
	outlineStyle: CSSProperty.OutlineStyle,
	outlineColor: Color,
];

export type Border<
	Color extends PositiveColor | NegativeColor = PositiveColor | NegativeColor,
> = [
	borderWidth: CSSProperty.BorderWidth,
	borderStyle: CSSProperty.BorderStyle,
	borderColor: Color,
];

export const defaultPlaneDivisionTransition = `border-color 200ms, background-color 200ms`;
