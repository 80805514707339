import { TranslationsMap } from './translations.types';

export const appStateTranslations = {
	defaultWindowTitle: {
		en: 'Business.gov.nl',
		nl: 'Ondernemersplein',
	},
	defaultErrorMessage: {
		en: 'The page is not available',
		nl: 'De pagina is niet bereikbaar',
	},
	serverErrorTitle: {
		en: '500 - Server error',
		nl: '500 - Server error',
	},
	serverErrorContent: {
		en: 'Unfortunately the webpage you have requested cannot be displayed.',
		nl: 'Helaas, de door u opgevraagde webpagina kan op dit moment niet worden weergegeven.',
	},
	transactionBlockErrorMessage: {
		en: 'An error has occurred. There is no connection with the server possible. Please try again later.',
		nl: 'Er is iets misgegaan. Er kon geen connectie met de server worden gemaakt. Probeer het later nog een keer.',
	},
	browseErrorTitle: {
		en: 'Error loading panel',
		nl: 'Server fout',
	},
	browseErrorMessage: {
		en: 'Something went wrong while trying to load this panel, please try again later.',
		nl: 'Er is iets misgegaan bij het inladen van dit paneel. Er kon geen connectie met de server worden gemaakt. Probeer het later nog een keer.',
	},
	componentLoading: {
		en: 'This functionality is being loaded.',
		nl: 'Deze functionaliteit wordt ingeladen.',
	},
	componentLoadingFailed: {
		en: 'Something went wrong while trying to load this functionality.',
		nl: 'Deze functionaliteit kon niet worden ingeladen.',
	},
	errorBoundaryFailed: {
		en: 'This component could not be loaded, please try again later.',
		nl: 'Dit onderdeel kon niet worden ingeladen, probeer het later nog eens.',
	},
	noDataError: {
		en: 'Sorry, no information is currently available',
		nl: 'Sorry, er is momenteel geen informatie beschikbaar',
	},
} as const;

// and cast `as TranslationsMap` to enforce 'en' and 'nl' languageCodes
appStateTranslations as TranslationsMap;
