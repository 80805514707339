'use client';

import { useEffect } from 'react';

export const UnlockABTasty = () => {
	useEffect(() => {
		// As soon as client is rendered, AB-tasty can be used (otherwise NextJS hydration errors will pop up)
		// See: https://developers.abtasty.com/docs/tag/tag-window#locking-tag-execution
		if ('unlockABTasty' in window) {
			window.unlockABTasty();
		}
	}, []);

	return null;
};
