import { createGlobalStyle, css } from 'styled-components';

import { getCSSForResponsiveProp } from '../base/getResponsiveCSS';
import { rootCapInPx } from './fontDefinitions';

export const RootCap = createGlobalStyle`
    :root {
		--cap-height: var(--root-cap);
		
        ${getCSSForResponsiveProp((rootCapInPx) => {
					return css`
						--root-cap: ${rootCapInPx}px;
					`;
				}, rootCapInPx)}
    }
`;
