'use client';

import { PropsWithChildren, createContext, useContext } from 'react';
import { Site } from '@/globals/webApiTypes/site.types';

const SiteDataContext = createContext<Site | undefined>(undefined);

export const SiteDataProviderClient = ({
	siteData,
	children,
}: PropsWithChildren<{
	siteData: Site;
}>) => {
	return (
		<SiteDataContext.Provider value={siteData}>
			{children}
		</SiteDataContext.Provider>
	);
};

export const useSiteDataSelector = <Selection,>(
	selector: (pageData: Site) => Selection,
) => {
	const siteData = useContext(SiteDataContext);

	if (siteData == null)
		throw new Error(`useSiteData must be used within SiteDataProvider`);

	return selector(siteData);
};
