'use client';

import React from 'react';

import './CSSReset';
import './styling.css';
import { RootCap } from '../font/RootCap';
import { RootColorTheme } from '../colorTheme/RootColorTheme';
import { BGOVColorTheme, OPColorTheme } from '../colorTheme/ColorTheme.types';

const UIPrimitivesRootStyle_ = ({
	rootColorTheme,
}: {
	rootColorTheme: OPColorTheme | BGOVColorTheme;
}): React.ReactElement | null => {
	return (
		<React.Fragment>
			<RootCap />
			<RootColorTheme rootColorTheme={rootColorTheme} />
		</React.Fragment>
	);
};

export const UIPrimitivesRootStyle = React.memo(UIPrimitivesRootStyle_);
