'use client';

import { AnyImmutableJsMap } from '@dop/shared/types/immutableJs';
import { fromJS } from 'immutable';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { PageCollection } from '../dataCollector/pageDataCollector/types/pageDataCollector.types';

const WebAPIContext = createContext<{ page: AnyImmutableJsMap } | undefined>(
	undefined,
);

export const PageDataProvider = ({
	page,
	children,
}: PropsWithChildren<PageCollection>) => {
	const data = useMemo(() => ({ page: fromJS(page) }), [page]);
	return (
		<WebAPIContext.Provider value={data}>{children}</WebAPIContext.Provider>
	);
};

export const usePageDataSelector = <Selection,>(
	selector: (pageData: AnyImmutableJsMap) => Selection,
) => {
	const data = useContext(WebAPIContext);

	if (data == null)
		throw new Error(`usePageDataSelector must be used within PageDataProvider`);

	const { page } = data;

	return selector(page);
};
