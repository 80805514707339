import { rechtsvormenTranslations } from '@dop/pages/rechtsvormen/translations/rechtsvormen.translations';

import { TranslationsMap } from './translations.types';
import { appStateTranslations } from './appStateTranslations';
import { dateTimeTranslations } from './dateTimeTranslations';

// cast `as const` to limit usage to existing keys
export const translations = {
	...rechtsvormenTranslations,
	...appStateTranslations,
	...dateTimeTranslations,
	aboutOurWebsite: {
		en: 'About this website',
		nl: 'Over deze site',
	},
	accepted: {
		en: 'Accepted',
		nl: 'Geaccepteerd',
	},
	accessibility: {
		en: 'Accessibility level: W3C WAI-AA WCAG 2.1',
		nl: 'Toegankelijkheidsniveau: W3C WAI-AA WCAG 2.1',
	},
	and: {
		en: 'and',
		nl: 'en',
	},
	attention: {
		en: 'Attention!',
		nl: 'Let op!',
	},
	back: {
		en: 'Back',
		nl: 'Terug',
	},
	'change settings': {
		en: 'Change settings',
		nl: 'Instellingen aanpassen',
	},
	clear: {
		en: 'Clear',
		nl: 'Wissen',
	},
	close: {
		en: 'Close',
		nl: 'Sluiten',
	},
	'close all': {
		en: 'Close all',
		nl: 'Alles sluiten',
	},
	closed: {
		en: 'closed',
		nl: 'gesloten',
	},
	closedMenuItem: {
		en: 'Closed menu item',
		nl: 'Gesloten menu item',
	},
	degrees: {
		en: 'degrees',
		nl: 'graden',
	},
	diameter: {
		en: 'diameter',
		nl: 'diameter',
	},
	'download my results': {
		en: 'Download my results (pdf)',
		nl: 'Download mijn uitslag (pdf)',
	},
	'download files': {
		en: 'Download files',
		nl: 'Download deze video',
	},
	'see results': {
		en: 'Watch these results online',
		nl: 'Bekijk deze resultaten online',
	},
	edit: {
		en: 'Edit',
		nl: 'Wijzigen',
	},
	email: {
		en: 'Email',
		nl: 'E-mail',
	},
	'external link': {
		en: 'External link',
		nl: 'Externe link',
	},
	form: {
		en: 'Contact form',
		nl: 'Contactformulier',
	},
	frequency: {
		en: 'Frequency',
		nl: 'Hoe vaak?',
	},
	tip: {
		en: 'Tip',
		nl: 'Tip',
	},
	tool: {
		en: 'Tool',
		nl: 'Regelhulp',
	},
	important: {
		en: 'Important',
		nl: 'Belangrijk',
	},
	info: {
		en: 'Info',
		nl: 'Info',
	},
	'info about': {
		en: 'Info about ${subject}',
		nl: 'Info over ${subject}',
	},
	googleReCaptchaMessageMarkdown: {
		en: 'This site is protected by reCAPTCHA and the Google [Privacy Policy](https://policies.google.com/privacy) and [Terms of Service](https://policies.google.com/terms) apply.',
		nl: 'Deze site wordt beschermd door reCAPTCHA waarvoor de Google [Privacy Policy](https://policies.google.com/privacy) en [Algemene Voorwaarden](https://policies.google.com/terms) gelden.',
	},
	// incase a form input error is incorrectly ommitted by the API
	inputError: {
		en: 'Please enter this field',
		nl: 'Controleer of u dit veld heeft ingevuld',
	},
	'last updated on': {
		en: 'Last updated on',
		nl: 'Laatst gecontroleerd op',
	},
	'leave this field blank': {
		en: 'Leave this field blank',
		nl: 'Laat dit veld leeg',
	},
	less: {
		en: 'Less',
		nl: 'Minder',
	},
	'link copied': {
		en: 'Link copied',
		nl: 'Link gekopieerd',
	},
	location: {
		en: 'Location',
		nl: 'Locatie',
	},
	logoLinkDescription: {
		en: ' Go to the homepage',
		nl: 'Ga naar de homepage',
	},
	max: {
		// context: "question 2 of max 3"
		en: 'Max',
		nl: 'Max',
	},
	menu: {
		en: 'Menu',
		nl: 'Menu',
	},
	messages: {
		en: 'Messages',
		nl: 'Berichten',
	},
	'min to read': {
		en: 'Min to read',
		nl: 'Min lezen',
	},
	modified: {
		en: 'Modified',
		nl: 'Bijgewerkt',
	},
	more: {
		en: 'More',
		nl: 'Meer',
	},
	'more results are loading': {
		en: 'More results are loading',
		nl: 'Meer resultaten worden geladen',
	},
	municipality: {
		en: 'Municipality',
		nl: 'Gemeente',
	},
	next: {
		en: 'Next',
		nl: 'Volgende',
	},
	of: {
		// context: "question 2 of max 3"
		en: 'of',
		nl: 'van',
	},
	'hide dialog': {
		en: 'Hide dialog "Suitable legal forms',
		nl: 'Dialog "Passende rechtsvormen" verbergen',
	},
	'show dialog': {
		en: 'Show dialog "Suitable legal forms"',
		nl: 'Dialog "Passende rechtsvormen" laten zien',
	},
	'suitable legal forms': {
		en: 'Suitable legal forms',
		nl: 'Passende rechtsvormen',
	},
	'on this page': {
		en: 'On this page',
		nl: 'Op deze pagina',
	},
	open: {
		en: 'Open',
		nl: 'Openen',
	},
	'open all': {
		en: 'Open all',
		nl: 'Alles openen',
	},
	opened: {
		en: 'opened',
		nl: 'geopend',
	},
	openedMenuItem: {
		en: 'Opened menu item',
		nl: 'Geopend menu item',
	},
	optional: {
		en: 'Optional',
		nl: 'Optioneel',
	},
	percent: {
		en: 'per cent',
		nl: 'procent',
	},
	permille: {
		en: 'per mille',
		nl: 'promile',
	},
	prinsjesdagSuitcase: {
		en: 'Prinsjesdag suitcase',
		nl: 'Prinsjesdag koffertje',
	},
	province: {
		en: 'Province',
		nl: 'Provincie',
	},
	'published by': {
		en: 'Published by',
		nl: 'Gepubliceerd door',
	},
	question: {
		en: 'Question',
		nl: 'Vraag',
	},
	'reading duration': {
		en: 'Reading duration',
		nl: 'Leestijd',
	},
	'related content': {
		en: 'Related content',
		nl: 'Gerelateerde content',
	},
	rijksLintjeLinkDescription: {
		en: 'Go to the homepage',
		nl: 'Ga naar de homepage',
	},
	rijksLintjeTitle: {
		en: 'Logo Dutch central government',
		nl: 'Logo Rijksoverheid',
	},
	search: {
		en: 'Search',
		nl: 'Zoeken',
	},
	'search-facet-all': {
		en: 'All',
		nl: 'Ondernemersplein',
	},
	'search-facet-forum': {
		en: 'Community',
		nl: 'Forum',
	},
	'search-facet-regulations': {
		en: 'Regulations',
		nl: 'Wetten en regels',
	},
	'search-facet-subsidies': {
		en: 'Subsidies',
		nl: 'Subsidies',
	},
	'search-facet-amendments': {
		en: 'Amendments',
		nl: 'Wetswijzigingen',
	},
	select: {
		en: 'Select',
		nl: 'Selecteer',
	},
	'send email': {
		en: 'Send an e-mail',
		nl: 'Stuur een e-mail',
	},
	'share my results': {
		en: 'Share my results',
		nl: 'Deel mijn uitslag',
	},
	siteDescription: {
		en: 'The official source of information for doing business in the Netherlands, made available by the Dutch government.',
		nl: 'Informatie en advies van de overheid voor ondernemers door KVK, Belastingdienst, RVO, CBS, UWV en andere overheidsorganisaties.',
	},
	siteSubTitle: {
		en: 'Information for entrepreneurs',
		nl: 'Alles van de overheid op één plek',
	},
	siteTitle: {
		en: 'Business.gov.nl',
		nl: 'Ondernemersplein',
	},
	summary: {
		en: 'Summary',
		nl: 'Samenvatting',
	},
	the: {
		en: 'The',
		nl: 'De',
	},
	'this article is related to': {
		en: 'This article is related to:',
		nl: 'Dit artikel is gerelateerd aan:',
	},
	'this information is posted by': {
		en: 'This information is posted by',
		nl: 'Deze informatie is geplaatst door',
	},
	'to top': {
		en: 'To top',
		nl: 'Naar boven',
	},
	version: {
		en: 'Version',
		nl: 'Versie',
	},
	'view your results': {
		en: 'View your results',
		nl: 'Bekijk uw resultaat',
	},
	'visit website': {
		en: 'Visit the website',
		nl: 'Bezoek de website',
	},
	'waste type': {
		en: 'Waste type',
		nl: 'Afvalsoort',
	},
	wasteGuidePdfError: {
		en: 'Sorry, there was a glitch. Please try again later.',
		nl: 'Sorry, er ging iets mis. Probeert u het later nog eens.',
	},
	waterboard: {
		en: 'Water authority',
		nl: 'Waterschap',
	},
	words: {
		en: 'Words',
		nl: 'Woorden',
	},
	yourEuropeLogo: {
		en: 'YourEurope, this webpage is part of an EU quality network',
		nl: 'Logo SDG YourEurope',
	},
	pro: {
		en: 'pro',
		nl: 'voordeel',
	},
	con: {
		en: 'con',
		nl: 'nadeel',
	},
	check: {
		en: 'checkmark',
		nl: 'vinkje',
	},
	fillOutForm: {
		en: 'Fill out form',
		nl: 'Invulformulier',
	},
	phoneNumber: {
		en: 'Telephone number',
		nl: 'Telefoonnummer',
	},
	xDotCom: {
		en: 'X (was Twitter)',
		nl: 'X (was Twitter)',
	},
} as const satisfies TranslationsMap;

export type Translations = typeof translations;
