import { translate } from '@dop/shared/translate/translate';
import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconLinkExternal: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '12 7 45 45', width: '45', height: '45' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		enableBackground="new 0 0 64 64"
		role="img"
		aria-label={translate('external link')}
		{...props}
	>
		<path
			d="M34,7v5H48.5L28.2,32.2l3.6,3.6L52,15.5V30h5V7Zm9,35a5,5,0,0,1-5,5H22a5,5,0,0,1-5-5V26a5,5,0,0,1,5-5H33.8l5-5H22A10,10,0,0,0,12,26V42A10,10,0,0,0,22,52H38A10,10,0,0,0,48,42V25.2l-5,5Z"
			transform="translate(0 0)"
		/>
	</svg>
);
